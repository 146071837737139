<template>
  <div class="zt-component-main">
    <div class="component-label" style="display: inline-block;">
      <slot></slot>
      {{ widget.componentName }}：
    </div>
    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">
      <div flex-row-flex-start-center>
        <template v-for="(item,index) in images">
          <el-image class="item-of-image" style="height: 200px;" :src="normalizeImageUrl(item)" alt="" :preview-src-list="[normalizeImageUrl(item)]"></el-image>
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

import ComponentMixin from './component-mixin'
import {isEmpty}      from 'lodash'

export default {
  extends   : ComponentMixin,
  mixins    : [],
  components: {},
  name      : 'ZtPhone',
  props     : {
    className: { type: String, default: '' },
    widget   : { type: Object, required: true },
  },
  data() {
    return {
      images: [],
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {},
  methods : {
    //
  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {
    if (isEmpty(this.widget)) {throw new Error('widget is empty')}
    // console.log('【ZtCountrySingle:79】',`this.widget = `,this.widget);
    if (Reflect.has(this.widget, 'answer') && this.widget) {
      let answer = this.widget.answer
      if (answer) {
        this.images = answer.split(',')
        // this.images = [
        //     'https://exp-picture.cdn.bcebos.com/65ba880b31210561c948885c08aee8d7582a6a41.jpg',
        //     'https://exp-picture.cdn.bcebos.com/65ba880b31210561c948885c08aee8d7582a6a41.jpg',
        //     'https://exp-picture.cdn.bcebos.com/65ba880b31210561c948885c08aee8d7582a6a41.jpg',
        // ]
      }
    }
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "component.scss";


</style>
